import type {
  FindLayoutImporterQuery,
  FindLayoutImporterQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import LayoutImporter from '../LayoutImporter'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindLayoutImporterQuery {
    layouts: hubDashLayoutsAsStafflink {
      id
      clientId
      name
      layoutData
      userFilterType
      searchAccessType
      allowUserFilterOverride
      cardCountByLayoutId
    }
    clients: clientsAsStafflink {
      id
      name
    }
  }
`

export type WorkflowsLayout = FindLayoutImporterQuery['layouts'][0]

export type WorkflowsClient = FindLayoutImporterQuery['clients'][0]

export const Loading = () => (
  <div className="flex h-[calc(100vh-160px)] items-center justify-center">
    <LoadingSpinner />
  </div>
)

export const Empty = () => {
  return (
    <div className="flex h-[calc(100vh-160px)] items-center justify-center">
      <p className="italic text-gray-400">No HubDash Layouts were found.</p>
    </div>
  )
}

export const Failure = ({
  error,
}: CellFailureProps<FindLayoutImporterQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  layouts,
  clients,
  queryResult: { refetch },
}: CellSuccessProps<
  FindLayoutImporterQuery,
  FindLayoutImporterQueryVariables
>) => {
  if (layouts?.length === 0) {
    return <Empty />
  }

  return (
    <LayoutImporter layouts={layouts} clients={clients} refetch={refetch} />
  )
}
